import React from 'react';
import * as entities from 'entities';
import { Link } from 'gatsby';

const ProductCard = ({ product }) => (
  <div key={entities.decodeHTML(product.productPage)} className="flex px-3 w-320 lg:w-280 mb-8">
    <div className="rounded-lg border border-solid border-gray-200 overflow-hidden"
         style={{
            boxShadow: '0 10px 28px rgba(0,0,0,.08)'
          }}>
      <Link to={product.productPage}><img src={product.image} alt={entities.decodeHTML(product.title)} title={entities.decodeHTML(product.title)} className="img400" /></Link>
      <div className="p-6">
        <p className="font-semibold text-xl lh:h-16 md:h-12">
          <Link to={product.productPage}><h3>{entities.decodeHTML(product.title)}</h3></Link>
        </p>
        <p className="mt-4 lg:h-32 md:h-20 ">
          {product.description}
        </p>
        <p className="mt-8 md:mt-6">
          <Link to={product.productPage} className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white py-2 px-3 rounded">weitere Details</Link>
        </p>
      </div>
    </div>
  </div>
);

export default ProductCard;
